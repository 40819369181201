
import { defineComponent } from 'vue'


export default defineComponent({
  name: 'App',

  methods: {
    push(url:string) {
        window.location.href = url
    }
  },

  data () {
    return {
      //
      links: [
        "Impressum",
        "Datenschutzerklärung",
        "Cookies",
        "Kontakt",
        "Wiederufserklärung",
        "AGB",
      ],
    }
  },
})
